class CartClicker {
    constructor(selector) {
        this.selector = selector;
        this.side_cart = document.querySelector('.js-side-cart');
        this.mask = document.querySelector('#mask');

        this._clickEvent = this._clickEvent.bind(this);
        this.selector.addEventListener('click', this._clickEvent);
    }
    _clickEvent(e) {
        e.preventDefault();

        if (this.side_cart !== null) {
            if (this.side_cart.classList.contains('side-cart--shown')) {
                this.side_cart.classList.remove('side-cart--shown')
                this.mask.classList.remove('shown');
                document.body.style.overflow = 'auto';
                // document.html.style.overflow = 'auto';
            } else {
                this.side_cart.classList.add('side-cart--shown');
                this.mask.classList.add('shown');
                document.body.style.overflow = 'hidden';
                // document.html.overflow = 'hidden';
            }
        }
    }
}
export default CartClicker;