class ProductVariations {
    constructor(selector) {
        this.selector = selector;
        this.productId = this.selector.getAttribute('data-product-id');
        this.container = this.selector.querySelector('[data-element="container"]');

        this._getFromAPI().then(response => {
            //console.log(response);
            if(response !== null){
                this._generateVariations(response.kolor);
                this.selector.classList.add('product-variations--shown');
            } else {
                this.selector.style.display = 'none';
            }
        }).catch(error => {
            this.selector.style.display = 'none';
        })
    }
    _getFromAPI(){
        let url = '/wp-json/netivo/v1/variations/' + this.productId;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _generateVariations(response) {
        let options = response.options;
        options.forEach(opt => {
             let product = this._generateProduct(opt);
             this.container.appendChild(product);
        });
    }
    _generateProduct(response) {
        let prod = document.createElement('a');
        prod.classList.add('product-variations__option');
        prod.setAttribute('href', response.product_link);
        prod.setAttribute('title', response.attribute_name);
        prod.style.backgroundColor = response.color;

        return prod;
    }
}
export default ProductVariations;